
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card outlined class="pa-5 mb-2">
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Country</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="country" outlined :items="countries" item-text="name" menu-props="auto" label="Select country" hide-details return-object single-line @change="SelectState"></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>State</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="state" outlined :items="states" item-text="name" menu-props="auto" label="Select State" hide-details return-object single-line @change="SelectSchoolDistrict"></v-select>
                    </v-col>
                </v-row>
            </v-col>
             <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>School District</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="schoolDistrict" outlined :items="schoolDistricts" item-text="name" menu-props="auto" label="Select School District" hide-details return-object single-line @change="SelectSchool"></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Schools</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="school" outlined :items="schools" item-text="name" menu-props="auto" label="Select School District" hide-details return-object single-line @change="SelectSchoolImport"></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-card outlined class="pa-5 mb-2">
        <v-row>
            <v-col cols="6">
             {{infoSchool}}
            </v-col>
            <v-col>
              <!-- <v-file-input label="File input" outlined dense @change="importf(this)"></v-file-input> -->
              <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
            </v-col>
            <v-col>
              <v-btn depressed @click="enviarDatos">Guardar</v-btn>
            </v-col>
        </v-row>
    </v-card>
    <div id="demo"></div>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
import UploadExcelComponent from '../../../components/uploadExcel'
import XLSX from 'xlsx'
export default {
  components: { UploadExcelComponent },
  data () {
    return {
      country: '',
      countries: [],
      state: '',
      states: [],
      schoolDistrict: '',
      schoolDistricts: [],
      school: '',
      schools: [],
      schoolSelected: {},
      dialogLoad: false,
      table: 'schoolAdmin',
      alertError: '',
      alertSuccess: '',
      infoSchool: '',
      teacher: {
        email:"sanphillips@leelee.com",
        password: "sanphillips",
        name: "Victor",
        lastName: "Alvarado",
        className : "1A",
        classType: "1st Grade",
        classroom:[

        // {lastname:'Cruz', name:'Jacob', cod:'4232548'},
        // {lastname:'Hernandez', name:'Joel', cod:'5015923'},
        // { lastname:'Iliany', name:'Velasquez', cod:'5165013'}, --Pre-School--Kindergarten--1st Grade--2nd Grade--3rd Grade
        ]
      }
    }
  },
  created () {
    this.ListCountries();
    // const setAdmin = functions.httpsCallable('crearUsuarioUidPersonalizado')
    // setAdmin();
    // setAdmin({role: 'student'}).then((doc) =>{
    //   console.log(doc);
    // });
  },
  methods: {
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {

        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectState () {
      let StateReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id);
      await StateReference.get().then((doc) => {
        let ListStates = doc.data().States;
        let ids = Object.keys(ListStates);
        let newStates = [];
        ids.forEach(element => {
          newStates.push({ id: element, name: ListStates[element] })
        });
        this.states = newStates;
        // aqui reiniciamos los demas selects
        this.SchoolDistrict = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolDistrict () {
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id);
      await SchoolDistrictReference.get().then((doc) => {
        // console.log(doc.data());
        let ListSchoolDistrict = doc.data().SchoolDistricts;
        let ids = Object.keys(ListSchoolDistrict);
        let newSchoolDistricts = [];
        ids.forEach(element => {
          newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
        });
        this.schoolDistricts = newSchoolDistricts;
        // aqui reiniciamos los demas selects

        //this.schools = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchool(){
        let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      await SchoolReference.get().then((doc) => {
        // console.log(doc.data());
        let ListSchool = doc.data().Schools;
        let ids = Object.keys(ListSchool);
        let newSchools = [];
        ids.forEach(element => {
          newSchools.push({ id: element, name: ListSchool[element] })
        });
        this.schools = newSchools;
        // aqui reiniciamos los demas selects

        //this.schools = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolImport() {
      let DataSchool = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection('Schools').doc(this.school.id);
      await DataSchool.get().then((doc) => {
        // console.log(doc.data());
        //let infoschool = doc.data().Schools;
        this.infoSchool = doc.data().SchoolName + ' , students licences assigned : ' + doc.data().TeacherLicencesAssigned + ' , teacher licences  assigned: ' + doc.data().StudentLicencesAssigned;
        this.schoolSelected = doc.data()
      }).catch((error) => {
        console.log(error)
      });
        // console.log("presionarlo");
        // console.log(this.school);
    },
    beforeUpload(file) {
      console.log(file);
      console.log(file.file);
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
      console.log(results);
      console.log(header);
    },
    async enviarDatos(){
      // CREAMOS TEACHER
      let teacher = this.teacher;
      teacher.typeLogin = "email"
      teacher.collection = "SchoolTeacher";
      teacher.school = this.school.name;
      teacher.reference = 'SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id;
      teacher.ListGradesReference = 'SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/GradeData/GradeD';
      teacher.dateExpiration = this.schoolSelected.DateExpired.toDate().getTime();
      teacher.Multiplayer = false;
      console.log(teacher)

      const setAdmin = functions.httpsCallable('createTeacher')
      await setAdmin(teacher);
      console.log("finalizo el proceso de crear teacher");
      const getTeacherData = functions.httpsCallable('getTeacherData')
      let datosTeacher = await getTeacherData({email:teacher.email})
      console.log(datosTeacher);
      var batch = db.batch();
      let slug = teacher.className
        .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
        .toLowerCase();
      slug = slug.replace(/^\s+|\s+$/gm, "");
      slug = slug.replace(/\s+/g, "-");
      batch.set(
        db
          .collection("SchoolTeacher")
          .doc(datosTeacher.data.uid)
          .collection("Classrooms")
          .doc(slug),
        {
          Name: teacher.className,
          Grade: teacher.classType,
          Groups: {},
          MainGroup: {},
        }
      );
      batch.update(db.collection("SchoolTeacher").doc(datosTeacher.data.uid), {
        [`Classrooms.${[slug]}`]: {
          Name: teacher.className,
          NumberStudents: 0,
          NumberGroup: 0,
        },
      });
      await batch.commit();
      console.log("aqui termino de agregar la clase");

      // // esto es la version sin crear teacher--BEGIN
      // const getTeacherData = functions.httpsCallable('getTeacherData')
      // let datosTeacher = await getTeacherData({email:this.teacher.email})//--
      let teacherDB = await db.collection("SchoolTeacher").doc(datosTeacher.data.uid).get();
      let teacherInfo =teacherDB.data();
      let classroomTeacher =  Object.keys(teacherInfo.Classrooms)
      let clasesRoom =this.teacher.classroom;
      // esto es la version sin crear teacher -- END
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fechaExam= month+"-"+day+"-"+year;
      let studentsGenerate= [];
      for (let element of clasesRoom) {
        let student = {};
        student.name = element.name;
        student.lastName = element.lastname;
        student.password = element.cod;
        student.email = element.cod+'@leelee.com';
        // student.idclass = '3pkb1p';
        student.idclass = classroomTeacher[0];
        student.teacher = teacherInfo.UserUID;
        student.schoolDistrict = this.schoolDistrict.name;
        student.school = this.school.name;
        student.dateExpiration =teacherInfo.LicenseExpirationDate.toDate().getTime();
        student.studentId = element.cod;
        student.reference = teacherInfo.Reference.path;
        student.fechaExam = fechaExam;
        console.log(student);
        const setAdmin = functions.httpsCallable('importDataHard')
        let studentCreated = await setAdmin(student);
        console.log(studentCreated);
        console.log(studentCreated.data.user);
        console.log(studentCreated.data.user.uid);
        studentsGenerate.push(studentCreated.data.user.uid);
      }
      console.log(studentsGenerate);
      await db.collection("SchoolTeacher").doc(teacherInfo.UserUID).collection("Classrooms").doc(classroomTeacher[0]).set({ Tests: { [`${fechaExam}`] : {Estado:true,Students:studentsGenerate} } }, { merge: true });
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New School District' : 'Edit School District'
    },
  },
  watch: {
    dialogSchoolDistrict (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
